var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "page-title-bar" }, [
      _c("i", { staticClass: "ico ico-user" }),
      _vm._v(_vm._s(_vm.$t("My Info")))
    ]),
    _c(
      "div",
      { staticClass: "myInfo mt-10" },
      [
        _c("v-form", { ref: "password" }, [
          _c("div", { staticClass: "box" }, [
            _c("h3", { staticClass: "page-sub-title" }, [
              _vm._v(_vm._s(_vm.$t("Info")))
            ]),
            _c("table", { staticClass: "tbl-data mt-3" }, [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "110px" } }),
                _c("col")
              ]),
              _c("tr", [
                _c("th", [_vm._v("Email")]),
                _c("td", [_vm._v(_vm._s(_vm.formData["emailAddress"]))])
              ]),
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _c("td", [_vm._v(_vm._s(_vm.formData["firstName"]))])
              ])
            ])
          ]),
          _c("div", { staticClass: "box" }, [
            _c("h3", { staticClass: "page-sub-title" }, [
              _vm._v(_vm._s(_vm.$t("Alarm & Report")))
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("v-switch", {
                  staticClass: "form-switch",
                  attrs: {
                    label: _vm.Label_Alarm,
                    inset: "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.formData["emailAlarm"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "emailAlarm", $$v)
                    },
                    expression: "formData['emailAlarm']"
                  }
                }),
                _c("v-switch", {
                  staticClass: "form-switch ml-16",
                  attrs: {
                    label: _vm.Label_Report,
                    inset: "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.formData["emailReport"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "emailReport", $$v)
                    },
                    expression: "formData['emailReport']"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "box d-flex-between" }, [
            _c("div", { staticClass: "column" }, [
              _c("h3", { staticClass: "page-sub-title" }, [
                _vm._v(_vm._s(_vm.$t("My Store")))
              ]),
              _c(
                "div",
                { staticClass: "listBox mt-2" },
                _vm._l(_vm.formData["managedStores"], function(store, index) {
                  return _c("ul", { key: index }, [
                    _c("li", {
                      domProps: {
                        textContent: _vm._s(
                          store.name + " (store code: " + store.code + ")"
                        )
                      }
                    })
                  ])
                }),
                0
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c("h3", { staticClass: "page-sub-title" }, [
                _vm._v(_vm._s(_vm.$t("My Level")))
              ]),
              _c("div", { staticClass: "listBox mt-2" }, [
                _c("ul", [
                  _c("li", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.formData["title"] +
                          "(" +
                          _vm.formData["accessLevel"] +
                          ")"
                      )
                    }
                  })
                ])
              ])
            ])
          ])
        ])
      ],
      1
    ),
    _c("div", { staticClass: "table-options" }, [
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-sm",
              attrs: { text: "" },
              on: { click: _vm.updateUserInfo }
            },
            [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "300" },
            model: {
              value: _vm.successDialog,
              callback: function($$v) {
                _vm.successDialog = $$v
              },
              expression: "successDialog"
            }
          },
          [
            _c(
              "v-card",
              { staticClass: "popup add_store_popup" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.message) }
                      })
                    ])
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: {
                          click: function($event) {
                            _vm.successDialog = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }